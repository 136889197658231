// Import js-packages from node_modules
import $ from "jquery";
import "jquery-youtube-background/src/jquery.youtubebackground.js";

/**
 * Initialize YouTube player in front page hero
 *
 */
export const initHeroYoutube = () => {
    const $video_bg = $("#video_bg");
    const id = $video_bg.attr("data-videoid");
    const { Cookiebot } = window;
    if (!Cookiebot.consent.marketing) {
        return;
    }

    if (id) {
        $video_bg.YTPlayer({
            videoId: id,
            fitToBackground: false,
            width: $(window).width(),
            mute: true,
            repeat: true,
            playerVars: {
                wmode: "transparent",
                modestbranding: 1,
                controls: 0,
                rel: 0,
                fs: 0,
                html5: 1,
                autoplay: 1,
            },
        });
    }
};
