import $ from "jquery";
import "jquery-youtube-background/src/jquery.youtubebackground";
import { initHeroYoutube } from "./lib/hero-youtube";

$(document).ready(() => {
    if ($(window).width() > 768) {
        if ($(".video-container").length > 0) {
            initHeroYoutube();
        }
    }
});
